@import '@driscollsinc/style-guide-web2.0/dist/index.css';

:root {
    --internal-primary: #6f5091;
    --internal-primary-main: #6f5091;
    --internal-primary-dark: #4e3f5e;
    --internal-primary-light: #ddd;
    --data-table-header-background: #6f5091;
    --data-table-header-color: white;
}
