.qmpApplicationPage {
    padding-left: 2rem;
    font-weight: 500;
    .rightAlign {
        justify-content: flex-end;
    }

    .multiSelectContainer {
        width: calc(100% - 35px);
        .multiSelectField {
            width: 100%;
            max-width: inherit;
        }
    }

    .selectContainer {
        width: 100%;
        margin-right: 35px;
        .selectField {
            width: 100%;
            max-width: inherit;
        }
    }

    .controls {
        justify-content: flex-end;
        button {
            width: 120px !important;
            border-radius: 0;
        }
    }

    .editForm {
        > div {
            width: 30%;
        }
        input {
            font-size: 16px;
        }
        & input:not(:placeholder-shown) + label {
            font-size: 14px;
        }
    }
}

.btnLink {
    width: 120px;
    border-radius: 0;
    font-size: 16px;
}

@media (max-width: 600px) {
    .qmpApplicationPage {
        padding-left: 0;
    }
}
