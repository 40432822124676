.callbackContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;

    .loginCallback {
        background-color: #ffffff;
        border-radius: 8px;
        border: 1px solid grey;
    }
}