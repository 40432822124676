.warehouseList {
    border: 1px solid var(--border-color);
    border-radius: 4px;
    padding: 1rem 2rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 30px;
    align-items: center;
}

.errorBorder {
    border-color: var(--error-main);
}

.cardWrapper {
    flex: 1 1 calc(33.333% - 30px);
    max-width: 250px;
    padding: 8px;
    border: none;
    border: 1px solid var(--border-color);
    border-radius: 10px;

    .cardBody {
        align-items: center;
        justify-content: space-between;

        .item {
            padding: 0;
            align-items: center;

            .icon {
                padding-right: 4px;
            }
        }
    }
}

.checkbox {
    >div {
        border: 1px solid var(--border-color-dark);
    }
}

.noData {
    width: 100%;
    justify-content: center;
    align-items: center;
}

.errorMessage {
    font-size: 12px;
    color: var(--error-main);
}