.bypassPage {
    padding-left: 1rem;
    padding-right: 1rem;
    margin-left: auto;
    margin-right: auto;
    font-weight: 500;
    max-width: 95%;
    width: 95%;

    .formWrapper {
        padding: 1.5rem;
        margin: 2rem auto;
        font-weight: 500;
        width: 75%;
        font-weight: 500;
        border: 1px solid var(--grey-500);
        border-radius: 5px;

        @media screen and (max-width: 450px) {
            width: 100%;
        }

        .fieldWrapper {
            display: grid;
            gap: 30px 10px;
            grid-template-columns: repeat(2, 1fr);
            width: 100%;

            @media screen and (max-width: 799px) {
                grid-template-columns: repeat(2, 1fr);
            }

            @media screen and (max-width: 450px) {
                grid-template-columns: repeat(1, 1fr);
            }

            .selectContainer {
                width: 100%;
                margin-right: 35px;
                margin-bottom: 14px;

                .selectField {
                    width: 100%;
                    max-width: unset;
                }
            }

            .datePickerContainer {
                width: 100%;

                label {
                    font-size: 12px;
                }
            }

            .datePicker {
                width: 100%;
                padding: 14px;
                font-size: 12px;
                font-weight: 500;
            }
        }
    }

    .btnLink {
        width: 120px;
        border-radius: 0;
        font-size: 16px;
    }
}

.selectContainer {
    width: 100%;
    margin-right: 35px;
    margin-bottom: 14px;

    .selectField {
        width: 100%;
        max-width: unset;
    }
}

.btnRounded {
    width: 120px;
    font-size: 16px;
    height: fit-content;
}

.header {
    justify-content: space-between;
    align-items: center;
}

.buttonSelectWrapper {
    padding: 0;
    padding-left: 1.2rem;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 2;
}

.compactSelectField {
    width: 20%;
    max-width: inherit;
}

.loadingOptions {
    display: flex;
    align-items: center;
    justify-content: center;
}

.rightAlign {
    align-items: center;
    justify-content: right;
}

.bypassLocationForm {
    padding-left: 16px;
}

.btnLink {
    width: 120px;
    font-size: 16px;
    height: fit-content;
}

@media (max-width: 600px) {
    .bypassPageWrapper {
        padding-left: 0;
    }
}