.addPersona {
    max-width: fit-content;
}

.entityInfo {
    padding: 10px 15px 30px 0px;
}

.personaCard {
    margin: 6px;
    .header {
        margin: 0;
        padding: 0;
        justify-content: space-between;
        border-bottom: 1px solid var(--grey-300);
        align-items: center;
        &.actions {
            padding: 0 0 0 4px;
        }
        button {
            margin: 2px;
        }
    }
    .theIcon {
        margin-right: 10px;
    }
    .district {
        box-shadow: 0px 20px 10px -20px var(--blue-200) inset;
    }
    .company {
        box-shadow: 0px 20px 10px -20px var(--orange-300) inset;
    }

    .ranch {
        box-shadow: 0px 20px 10px -20px var(--yellow-200) inset;
    }
    .entitiesContainer {
        max-height: 200px;
        overflow: auto;
        .entityInfo {
            margin: 4px;
            min-height: 16px;
            max-width: 145px;
        }
    }
    .personasContainer {
        justify-content: center;
    }
}
