.container {
    overflow: auto;
    margin: 0 15px 0 0 !important;
}

.actionColumn {
    align-items: center;
}

.actionRow {
    font-weight: 500;
    font-size: 13px;
}

.activeStatus {
    color: #3ab517;
}

.inActiveStatus {
    color: #f93d22;
}

.emailValue {
    font-weight: 500;
    font-size: 13px;
    color: #6f5091;
    text-decoration: none;
}

.dateTime {
    font-weight: 500;
    font-size: 13px;
}

.ellipsis {
    overflow: hidden;
    width: 80px;
    display: block;
    text-overflow: ellipsis;
}

.scoreTableContainer {
    margin: 20px !important;
    border: 0 !important;
    width: 30% !important;
    max-height: 300px;
    overflow: auto;
}
