.userManagementPage {
    padding-left: 1rem;
    padding-right: 1rem;
    margin-left: auto;
    margin-right: auto;
    font-weight: 500;
    max-width: 95%;
    width: 95%;

    .formWrapper {
        padding: 1.5rem;
        margin: 2rem auto;
        font-weight: 500;
        width: 75%;
        font-weight: 500;
        border: 1px solid var(--grey-500);
        border-radius: 5px;

        .fieldWrapper {
            display: grid;
            gap: 2rem;
            grid-template-columns: repeat(2, 1fr);
            width: 100%;

            @media screen and (max-width: 450px) {
                grid-template-columns: repeat(1, 1fr);
            }
        }

        .selectContainer {
            width: 100%;

            .selectField {
                width: 100%;
                max-width: inherit;
            }
        }

        .textField {
            height: 45.33px;
            width: 100%;
            border-color: var(--border-color);

            input {
                height: 45.33px;
                width: 100%;
            }
        }
    }

    .btnLink {
        width: 120px;
        border-radius: 0;
        font-size: 16px;
    }
}

.rightAlign {
    align-items: center;
    justify-content: right;
}


@media (max-width: 800px) {
    .userManagementPage {
        padding-left: 0;
    }
}