@import "../../../index.scss";

.container {
  div:first-child {
    padding: 0px;
  }

  table {
    margin: 0;
  }
}