.errorFallback {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: var(--white-0);
    border-left: 5px solid var(--red-350);
}

.title2 {
    color: var(--green-500);
}
